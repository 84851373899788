.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

h2 {
  font-weight: 400;
}
.error-p p {
  color: #888;
}

.left-panel-track {
  text-align: left;
}

.left-panel-track .panel_row {
  background: #2a2a2a;
  color: #19b7f4;
  padding: 4px 10px;
  font-size: 12px;
  
}

.border-bottom-white-2 {
  border-bottom: 2px solid #fff;

}
.border-right-white-2 {
  border-right: 2px solid #fff;

}
.left-panel-track .panel_row div {
  font-size: 12px;
}
.left-panel-track .panel_row:hover {
  background: #2a2a2a;
}
.left-panel-track .order_row {
  padding: 14px 10px;
}
.order_row .order_num {
  color: white;
  font-size: 18px;
}
.panel_data {
  position: relative;
  cursor: pointer;
  padding-top: 10px;
  padding-left: 10px;
  background-color: #ececec
}
.text_content {
  font-size: 10px;
    color: #aaa;
}

.dec_content {
  color: #000;
  font-size: 15px;
  padding-bottom: 10px;
  font-weight: 400;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.custom-stepper-component .MuiStepLabel-root{
  position: relative;
  align-items: baseline;
  padding-bottom: 50px;
}
.custom-stepper-component .MuiStepLabel-root:before {
  content: '';
  width: 4px;
  height: 100%;
  background-color: #EEEFF2;
  position: absolute;
  top: 0;
  left: 14px;
}
.custom-step-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #bbb;;
  color: #fff;
  /* font-family: $font-family-bold; */
}
.custom-step-icon-null {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: transparent;
  color: transparent;
}
.MuiStep-root:first-child .MuiStepLabel-root {
  padding-bottom: 20px;
}

.MuiStep-root:last-child .MuiStepLabel-root:before{
  content: none;
}
.custom_linear_stepper .MuiStepLabel-labelContainer {
  display: none;
}
.In_Progress .custom-step-icon {
  background-color: #3b99fc
}

.In_Progress {
  color: #3b99fc
}

.Completed .custom-step-icon {
  background-color: #2ac326
}

.Completed {
  color: #2ac326
}